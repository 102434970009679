<template>
  <b-card-code no-body title="Topup Collateral List">
    <div class="dt_adv_search ml-1 mr-1">
        <div class="row">

         <div class="col-3">
              <select class="form-control form-control-sm" v-model="filter.status">
                <option value="">-- All Status --</option>
                <option value="pending">Pending</option>
                <option value="success">Success</option>
                <option value="failed">Failed/Rejected</option>
                <option value="expired">Expired</option>
              </select>
          </div>
          <div class="col-3">
              <input type="number" v-model="filter.amount" placeholder="Filter by topup amount..." class="form-control form-control-sm">
          </div>

          <div class="col-6">
            <button class="btn btn-success btn-sm float-right" @click="exportTopup()">
              <feather-icon
                icon="DownloadCloudIcon"
              />
              Export Topup
            </button>
          </div>

        </div>

        <!-- Update Topup -->
        <b-modal no-close-on-backdrop id="form-modal" :title="editUuid != null ? 'Edit Topup' : 'Add Topup'">
          <div class="form">

            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">{{ formPayload.currency_code }}</span>
              </div>
              <input type="number" class="form-control" placeholder="Topup amount" v-model="formPayload.amount">
            </div>

          </div>

          <template #modal-footer="{}">
            <!-- <b-button v-if="editUuid == null" variant="success" @click="createItem()">
              Save Topup
            </b-button> -->
            <b-button variant="success" @click="updateItem()">
              Save Topup
            </b-button>
          </template>
        </b-modal>
        <!-- Update Topup -->

        <!-- Approve Topup -->
        <b-modal no-close-on-backdrop id="approve-modal" :title="'Approve Topup'">
          <div class="form">

            <label for="">Amount</label>
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">{{ approvePayload.currency_code }}</span>
              </div>
              <input type="number" class="form-control" placeholder="Topup amount" v-model="approvePayload.amount" @keyup="calculateReceivedCollateral()">
            </div>

            <br>
            <div class="form-group">
                <label for="">Active Until</label><br>
                <flat-pickr
                    v-model="approvePayload.expire_date"
                    class="form-control"
                    placeholder="Select date"
                    :config="{ dateFormat: 'Y-m-d', static: true}"
                    />
            </div>

            <div class="form-group">
                <label for="">Expire Alert in</label><br>
                <div class="input-group">
                    <input type="number" class="form-control" v-model="approvePayload.expire_alert_in" placeholder="3">
                    <div class="input-group-append">
                        <span class="input-group-text">days before expire</span>
                    </div>
                </div>
            </div>

            <!-- Collateral Type -->
            <div class="form-group">
              <label for="collateral_type">Collateral Type:</label>
              <select id="collateral_type" v-model="approvePayload.collateral_type_uuid" class="form-control" @change="calculateReceivedCollateral()">
                <option v-for="collateralType in collateralTypes" v-bind:value="collateralType.uuid" v-bind:key="collateralType.uuid">{{ collateralType.name }} (Haircut {{ collateralType.haircut }}%)</option>
              </select>
            </div>

            <!-- Amount will received by user -->
            <div>
              <label for="">Collateral will be received by Trader:</label>
              <h2>{{ (collateralReceived).toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
              }) }}</h2>
              <br>
            </div>

          </div>

          <template #modal-footer="{}">
            <!-- <b-button v-if="editUuid == null" variant="success" @click="createItem()">
              Save Topup
            </b-button> -->
            <b-button variant="success" @click="approveTopup()">
              Approve Topup
            </b-button>
          </template>
        </b-modal>
        <!-- Approve Topup -->


    </div>
    <br>
    <div class="table-responsive-sm">
      <table class="table b-table table-fixed">
        <thead>
          <tr>
            <th>User</th>
            <th>Amount</th>
            <th>Status</th>
            <th>Topup Time</th>
            <th>File Attachment</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="isLoading">
            <td colspan="6" class="text-center">
              <b-spinner
                class="mb-1"
                variant="primary"
              /><br>
              Loading...
            </td>
          </tr>
          <tr v-else v-for="item in result.data" v-bind:key="item.id">
            <td v-if="item.user != null">
                <router-link :to="{ name: 'users.show', params: { id: item.user.id } }">
                    {{ item.user.code }}<br><small>{{ item.user.email }}</small>
                </router-link>
            </td>
            <td v-else>-</td>
            <td>{{ item.amount_formatted }}</td>
            <td class="text-capitalize">
                <span class="badge text-capitalize badge-pill" v-bind:class="getStatusColor(item.status)">{{ item.status }}</span>
            </td>
            <td>{{ item.topup_time }} <span v-if="item.status == 'pending'"></span></td>
            <td>
                <strong v-if="item.file_attachment_url"><a v-bind:href="item.file_attachment_url" target="_blank">Open File</a></strong><span v-else>-</span><br>
            </td>
            <td>
              <div class="btn-group" role="group" v-if="item.status == 'pending'">
                <button class="btn btn-success btn-sm" v-if="checkPermission('approve topup')" @click="approveModal(item)" style="margin-left: 2px;" data-toggle="tooltip" data-placement="top" title="Approve">
                  <feather-icon
                    icon="CheckCircleIcon"
                  />
                </button>
                <button class="btn btn-info btn-sm" v-if="checkPermission('update topup')" @click="editItem(item)" style="margin-left: 2px;" data-toggle="tooltip" data-placement="top" title="Edit Topup">
                  <feather-icon
                    icon="EditIcon"
                  />
                </button>
                <button class="btn btn-danger btn-sm" v-if="checkPermission('reject topup')" @click="rejectTopup(item)" style="margin-left: 2px;" data-toggle="tooltip" data-placement="top" title="Reject Topup">
                  <feather-icon
                    icon="XCircleIcon"
                  />
                </button>
              </div>
            </td>
          </tr>
          <tr v-if="result.total == 0 && !isLoading">
            <td colspan="6" class="text-center">Data is empty.</td>
          </tr>
        </tbody>
      </table>

      <div class="m-1" v-if="result.total > 0">
        <div class="row">
          <div class="col">
            <small>Showing {{ result.from }} to {{ result.to }} from {{ result.total }}</small>
          </div>
          <div class="col">
            <pagination :data="result" @pagination-change-page="getData" :limit="4" align="right"></pagination>
          </div>
        </div>
      </div>
    </div>
  </b-card-code>
</template>

<script>
import _ from 'lodash'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { BCard, BCardText, BButton, BDropdown, BDropdownItem, BSpinner } from 'bootstrap-vue'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'
import { EditIcon } from 'vue-feather-icons'
import FileSaver from 'file-saver'
import moment from 'moment'
import flatPickr from 'vue-flatpickr-component'

export default {
  title () {
    return `Topup List`
  },
  components: {
    BCard,
    BButton,
    BCardText,
    BCardCode,
    BDropdown,
    BDropdownItem,
    EditIcon,
    BSpinner,
    flatPickr
  },
  setup(props) {
    return {
      checkPermission,
      successNotification,
      errorNotification,
    }
  },
  data() {
    return {
      currentPage: 1,
      result: {},
      filter: {
        status: this.$route.query.status == null ? '' : this.$route.query.status,
        amount: '',
        type: 'bg'
      },
      editUuid: null,
      formPayload: {
          title: '',
          date: ''
      },
      approvePayload: {
          expire_date: '',
          expire_alert_in: '',
          amount: '',
          collateral_type_uuid: null,
      },
      collateralTypes: [],
      collateralReceived: 0,
      isLoading: false,
    }
  },
  watch: {
    filter: {
      handler: _.debounce(function() {  
        this.getData();                                                                                                                                                                        
      }, 300),
      deep: true
    },
  },
  created() {
    this.getData()
    this.loadCollateralTypes()
  },
  methods: {
    loadCollateralTypes() {
      this.$http.get('/admin/collateral-type?per_page=100')
      .then(response => {
        this.collateralTypes = response.data.data.data
        this.approvePayload.collateral_type_uuid = this.collateralTypes[0].uuid
        this.calculateReceivedCollateral()
      })
    },
    calculateReceivedCollateral() {
      const context = this
      if (context.approvePayload.collateral_type_uuid != null) {
        var collateralType = context.collateralTypes.find(function(type){
          return type.uuid == context.approvePayload.collateral_type_uuid
        })

        var haircut = (collateralType.haircut / 100) * this.approvePayload.amount
        this.collateralReceived = this.approvePayload.amount - haircut
      }
    },
    getData(page = 1) {

      this.isLoading = true;
      this.currentPage = page;
      var queryParams = this.filter
      queryParams.page = page

      this.$http.get('/admin/topups', {
        params: queryParams
      })
      .then(response => {
        this.result = response.data.data
        this.isLoading = false;
      })

    },
    exportTopup() {
      var queryParams = this.filter

      this.$http.get('/admin/topups/export/csv', {
        params: queryParams,
        responseType: 'blob'
      })
      .then(response => {        
        var timestamp = moment().format('YYYY:MM:DD:HH:mm:ss');
        var fileName = 'topup-' + timestamp  + '.csv';
        FileSaver.saveAs(response.data, fileName);
      })
    },
    cleanupForm() {
      this.editUuid = null
      this.formPayload = {
        amount: '',
        currency_code: '',
      }
    },
    editItem(item) {
      this.editUuid = item.uuid
      this.formPayload = {
        amount: item.amount,
        currency_code: item.currency_code
      }
      this.$bvModal.show('form-modal')
    },
    approveModal(item) {
      this.editUuid = item.uuid
      this.approvePayload = {
        amount: item.amount,
        currency_code: item.currency_code,
        expire_date: '',
        expire_alert_in: 3,
        collateral_type_uuid: this.collateralTypes[0].uuid
      }
      this.$bvModal.show('approve-modal')
    },
    updateItem() {
      this.$http.patch('/admin/topups/' + this.editUuid, this.formPayload)
      .then(response => {

        this.$bvModal.hide('form-modal')
        this.getData(this.currentPage)
        successNotification(this, 'Success', 'Topup successfully updated!')

        this.cleanupForm();

      })
      .catch(error => {
        if (error.response.data.meta.messages.length > 0) {
          errorNotification(this, 'Oops!', error.response.data.meta.messages)   
        }
      })
    },
    approveTopup() {

        this.$swal({
            title: 'Are you sure?',
            text: "Balance will be added to User's Cash Balance!",
            icon: 'warning',
            showCancelButton: true,
            customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
            },
            buttonsStyling: false,
        }).then(result => {
            if (result.value) {
            
                this.$http.post('/admin/topups/' + this.editUuid + '/approve', this.approvePayload)
                .then(response => {
            
                    this.getData(this.currentPage)
                    this.$swal({
                    icon: 'success',
                    title: 'Approved!',
                    text: "Balance has been added to User's BG Balance.",
                    customClass: {
                        confirmButton: 'btn btn-success',
                    },
                    })
                    this.cleanupForm();
                    this.$bvModal.hide('approve-modal')

                })
                .catch(error => {
                    if (error.response.data.meta.messages.length > 0) {
                        errorNotification(this, 'Oops!', error.response.data.meta.messages)   
                    }
                })

            }
        })


    },
    rejectTopup(item) {

      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          
          this.$http.post('/admin/topups/' + item.uuid + '/reject')
          .then(response => {
    
            this.getData(this.currentPage)
            this.$swal({
              icon: 'success',
              title: 'Rejected!',
              text: "Topup rejected.",
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })

          })
        }
      })

    },
    getStatusColor(status) {
      switch (status) {
        case 'success':
          return 'badge-light-success';
          break;

        case 'expired':
          return 'badge-light-danger';
          break;

        case 'failed':
          return 'badge-light-danger';
          break;
      
        default:
          return 'badge-light-secondary';
          break;
      }
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>